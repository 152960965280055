import React from 'react';
import './FrontMainSection.css';

import AppstoreButton from '../../../static/images/AppstoreButton.svg';
import HeaderSectionIPhone from '../../../static/images/FrontPage-iPhone.png';

const FrontMainSection: React.FC = () => {
  return (
    <div className='HeaderSection'>
      <div className='TextSection'>
        <h1>
          Log Workouts
          <br />
          <span className='ColoredHeader'>Reach Goals</span>
        </h1>
        <span className='SecondHeadline'>
          Repify is a free app for iOS that helps you monitor and plan your
          workouts. Create templates, track progress and share achievements with
          friends.
        </span>
        <h3>Get the app for free</h3>
        <a
          href='https://apps.apple.com/de/app/repify/id6446846322'
          target='__blank'
        >
          <img src={AppstoreButton} alt='Download on the AppStore' />
        </a>
      </div>
      <div className='ImageSection'>
        <img className='Image' src={HeaderSectionIPhone} alt='iPhone' />
      </div>
    </div>
  );
};

export default FrontMainSection;
