import React from 'react';
import './FrontLogWorkoutsSection.css';

import LogWorkoutsiPhone from '../../../static/images/LogWorkoutsiPhone.png';
import checkmark from '../../../static/images/checkmark.svg';

const FrontLogWorkoutsSection: React.FC = () => {
  return (
    <div className='LogWorkousSection'>
      <div className='ImageSection'>
        <img src={LogWorkoutsiPhone} alt='Log Workouts' />
      </div>
      <div className='TextSection'>
        <h2>Track Workouts</h2>
        <span>
          A seamless workout tracker. Providing everything you need to achieve
          your fitness goals.
        </span>
        <div>
          <img src={checkmark} alt='Checkmark' />
          <span>Intuitive Workout Tracking</span>
        </div>
        <div>
          <img src={checkmark} alt='Checkmark' />
          <span>Advanced Template Planner</span>
        </div>
        <div>
          <img src={checkmark} alt='Checkmark' />
          <span>Make Warmup, Drop and Failure Sets</span>
        </div>
      </div>
    </div>
  );
};

export default FrontLogWorkoutsSection;
