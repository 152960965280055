import React from 'react';
import './About.css';

const About: React.FC = () => {
  return (
    <div className='About'>
      <div className='AboutInnerContainer'>
        <div className='AboutContent'>
          <h1>About</h1>
          <p>
            Looking to take your fitness journey to the next level? Look no
            further than Repify! Our cutting-edge fitness app is designed to
            help you track your progress in the gym and visualize the progress
            you've made.
          </p>
          <p>
            With Repify, you can easily set and track your fitness goals,
            monitor your daily workouts, and keep track of your progress over
            time. Plus, our intuitive interface makes it easy to visualize your
            progress and see how far you've come.
          </p>
          <p>
            But Repify isn't just about tracking your own progress – it's also a
            social fitness app that connects you with your friends and fitness
            buddies. With our social features, you can see your friends'
            progress, share your own achievements, and motivate each other to
            reach new heights in your fitness journey.
          </p>
          <p>
            Whether you're a beginner or a seasoned athlete, Repify has
            everything you need to take your fitness game to the next level. So
            why wait? Download Repify today and start achieving your fitness
            goals like never before!
          </p>
        </div>
      </div>
    </div>
  );
};

export default About;
