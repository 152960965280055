import React from 'react';
import './FrontProgressSection.css';

import ProgressiPhone from '../../../static/images/SeeProgressiPhone.png';
import checkmark from '../../../static/images/checkmark.svg';

const FrontProgressSection: React.FC = () => {
  return (
    <div className='ProgressSection'>
      <div className='TextSection'>
        <h2>Measure Your Progress</h2>
        <span>
          It's easier to maintain your drive when you can observe the progress
          you've made so far.
        </span>
        <div>
          <img src={checkmark} alt='Checkmark' />
          <span>Advanced Exercise Charts</span>
        </div>
        <div>
          <img src={checkmark} alt='Checkmark' />
          <span>Advanced Body Measurement Charts</span>
        </div>
        <div>
          <img src={checkmark} alt='Checkmark' />
          <span>Personal Records</span>
        </div>
        <div>
          <img src={checkmark} alt='Checkmark' />
          <span>Compare One-Rep-Max, Relative Strength, Volume and Weight</span>
        </div>
      </div>
      <div className='ImageSection'>
        <img src={ProgressiPhone} alt='Measure Your Progress' />
      </div>
    </div>
  );
};

export default FrontProgressSection;
