import React from 'react';

enum Viewport {
  S = 'S',
  M = 'M',
  L = 'L',
}

const breakpoints = {
  S: 450,
  M: 766,
  L: 767,
};
interface ViewportProps {
  viewport: Viewport;
}

type ViewportContextProps = {
  children?: React.ReactNode;
};

export const ViewportContext = React.createContext<ViewportProps>(
  {} as ViewportProps
);

const ViewportProvider: React.FC<ViewportContextProps> = ({
  children,
}: ViewportContextProps) => {
  const [width, setWidth] = React.useState(window.innerWidth);

  const handleWindowResize = () => {
    setWidth(window.innerWidth);
  };

  const viewport: () => Viewport = () => {
    if (width <= breakpoints.S) return Viewport.S;
    if (breakpoints.S < width && width <= breakpoints.M) return Viewport.M;
    return Viewport.L;
  };

  React.useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    return () => window.removeEventListener('resize', handleWindowResize);
  }, []);

  return (
    <ViewportContext.Provider value={{ viewport: viewport() }}>
      {children}
    </ViewportContext.Provider>
  );
};

export default ViewportProvider;
export { Viewport };
