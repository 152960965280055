import React from 'react';
import './FooterDownload.css';

import AppstoreButton from '../../../static/images/AppstoreButton.svg';

const FooterDownload: React.FC = () => {
  return (
    <div className='DownloadSection'>
      <div className='DownloadInnerSection'>
        <div className='FooterDownload'>
          <div className='TextSection'>
            <h2>Ready to get started?</h2>
            <span>Get Repify for free on iOS.</span>
          </div>
          <div className='ButtonSection'>
            <a
              href='https://apps.apple.com/de/app/repify/id6446846322'
              target='__blank'
            >
              <img src={AppstoreButton} alt='Download on the AppStore' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterDownload;
