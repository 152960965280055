import React, { useEffect, useState } from 'react';
import './ResetPassword.css';
import axios from 'axios';
import { Buffer } from 'buffer';
import { RotatingLines } from 'react-loader-spinner';

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSendTriggered, setIsSendTriggered] = useState(false);
  const [id, setId] = useState('');

  useEffect(() => {
    let pathname = window.location.pathname.toString();
    pathname = pathname.split('/')[2];
    let decodedPath = Buffer.from(pathname, 'base64').toString('ascii');
    setId(decodedPath);
  }, [setId]);

  const inputHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    switch (event.target.name) {
      case 'Password': {
        setPassword(event.target.value);
        break;
      }
      case 'RepeatPassword': {
        setRepeatPassword(event.target.value);
        break;
      }
    }
  };

  const triggerSend = () => {
    if (password === '' || repeatPassword === '') {
      setError('Please fill out all fields!');
    } else if (password !== repeatPassword) {
      setError('The passwords do not match!');
    } else {
      setIsSendTriggered(true);
      send();
    }
  };

  const send = async () => {
    const passwordMessage = {
      password: password,
    };

    const requestOptions = {
      url: process.env.REACT_APP_SERVICE_URL,
      method: 'POST',
      headers: {
        Authorization: 'Bearer ' + id,
      },
      data: passwordMessage,
    };

    try {
      const response = await axios(requestOptions);
      if (response.data === true) {
        setSuccessMessage('Password was successfully changed!');
      } else {
        setError('Something went wrong!');
      }
    } catch (error) {
      console.log(error);
      setError('Something went wrong!');
    }

    setIsSendTriggered(false);
  };

  return (
    <div className='ResetPassword'>
      <div className='ResetPasswordInnerContainer'>
        <div className='ResetPasswordContent'>
          <h1>Reset Password</h1>
          <h3>New Password</h3>
          <input
            type='password'
            name='Password'
            onChange={inputHandler}
            value={password}
            placeholder='New Password'
          />
          <h3>Repeat New Password</h3>
          <input
            type='password'
            name='RepeatPassword'
            onChange={inputHandler}
            value={repeatPassword}
            placeholder='Repeat New Password'
          />
          {error !== '' ? (
            <div className='ErrorContainer'>
              <span>{error}</span>
            </div>
          ) : null}
          {successMessage !== '' ? (
            <div className='SuccessContainer'>
              <span>{successMessage}</span>
            </div>
          ) : null}
          {isSendTriggered ? (
            <div className='LoadingContainer'>
              <RotatingLines
                strokeColor='grey'
                strokeWidth='5'
                animationDuration='0.75'
                width='30'
                visible={true}
              />
            </div>
          ) : null}
          <div className='ButtonContainer'>
            <button onClick={triggerSend}>Set Password</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
