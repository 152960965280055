import React from 'react';
import './Imprint.css';

const Imprint: React.FC = () => {
  return (
    <div className='Imprint'>
      <div className='ImprintInnerContainer'>
        <div className='ImprintContent'>
          <h1>Imprint</h1>
          <h3>Details according to § 5 TMG</h3>
          <span>David Junker</span>
          <span>Coinmanufactory UG (haftungsbeschränkt)</span>
          <span>Gorch-Fock-Kehre 20</span>
          <span>25451 Quickborn</span>
          <span>Germany</span>
          <h3>Contact</h3>
          <span>Email: contact@repifyworkout.com</span>
        </div>
      </div>
    </div>
  );
};

export default Imprint;
