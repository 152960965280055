import React, { useState, useContext } from 'react';
import './Navigation.css';
import { Link } from 'react-router-dom';
import { ViewportContext, Viewport } from '../../../providers/ViewportProvider';

import UnfoldedMenu from '../../molecules/unfoldedMenu/UnfoldedMenu';

import Logo from '../../../static/images/R.svg';
import Menubars from '../../../static/images/menubars.svg';

const Navigation: React.FC = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { viewport } = useContext(ViewportContext);

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <div className='Navigation'>
      <div className='NavigationInnerContainer'>
        <div className='LogoContainer'>
          <Link
            to={{ pathname: '/' }}
            onClick={() => {
              scrollTop();
            }}
          >
            <img className='LogoImage' src={Logo} alt='Repify Logo' />
          </Link>
        </div>
        {viewport === Viewport.L ? (
          <div className='MenuContainer'>
            <Link
              className='Link SpaceLink'
              to={{ pathname: '/' }}
              onClick={() => {
                scrollTop();
              }}
            >
              <span>Home</span>
            </Link>
            <Link
              className='Link'
              to={{ pathname: '/about' }}
              onClick={() => {
                scrollTop();
              }}
            >
              <span>About</span>
            </Link>
          </div>
        ) : (
          <div className='MobileMenuContainer'>
            <img src={Menubars} alt='Menu' onClick={() => setShowMenu(true)} />
          </div>
        )}
        {showMenu ? (
          <UnfoldedMenu
            menuClick={() => setShowMenu(false)}
            scrollTop={() => scrollTop()}
          />
        ) : null}
      </div>
    </div>
  );
};

export default Navigation;
