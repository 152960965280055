import React from 'react';
import './FrontSocialSection.css';

// import LogWorkoutsiPhone from '../../../static/images/LogWorkoutsiPhone.png';
import checkmark from '../../../static/images/checkmark.svg';
import SocialiPhone from '../../../static/images/communityiPhone.png';

const FrontSocialSection: React.FC = () => {
  return (
    <div className='SocialSection'>
      <div className='ImageSection'>
        <img src={SocialiPhone} alt='Join The Community' />
      </div>
      <div className='TextSection'>
        <h2>Become a Repify Athlete</h2>
        <span>
          Keep up with other athletes and engage in healthy competition with
          them.
        </span>
        <div>
          <img src={checkmark} alt='Checkmark' />
          <span>Follow Other Athletes</span>
        </div>
        <div>
          <img src={checkmark} alt='Checkmark' />
          <span>Like and Comment on Workouts</span>
        </div>
        <div>
          <img src={checkmark} alt='Checkmark' />
          <span>Save Athlete's Templates</span>
        </div>
        <div>
          <img src={checkmark} alt='Checkmark' />
          <span>Stay Accountable</span>
        </div>
      </div>
    </div>
  );
};

export default FrontSocialSection;
