import React from 'react';
import './UnfoldedMenu.css';
import { Link } from 'react-router-dom';

import Logo from '../../../static/images/R.svg';
import Menubars from '../../../static/images/menubars.svg';

type Props = {
  menuClick(): void;
  scrollTop(): void;
};

const UnfoldedMenu: React.FC<Props> = ({ menuClick, scrollTop }: Props) => {
  return (
    <div className='UnfoldedMenu'>
      <div className='Menu'>
        <div className='MenuLogo'>
          <Link
            to={{ pathname: '/' }}
            onClick={() => {
              menuClick();
              scrollTop();
            }}
          >
            <img className='LogoImage' src={Logo} alt='Repify Logo' />
          </Link>
        </div>
        <div className='MenuIcon'>
          <img
            src={Menubars}
            alt='Menu'
            onClick={() => {
              menuClick();
              scrollTop();
            }}
          />
        </div>
      </div>
      <Link
        className='Link'
        to={{ pathname: '/' }}
        onClick={() => {
          menuClick();
          scrollTop();
        }}
      >
        <span>Home</span>
      </Link>
      <Link
        className='Link'
        to={{ pathname: '/about' }}
        onClick={() => {
          menuClick();
          scrollTop();
        }}
      >
        <span>About</span>
      </Link>
    </div>
  );
};

export default UnfoldedMenu;
