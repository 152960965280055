import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

const Footer: React.FC = () => {
  const getYear = () => {
    return new Date().getFullYear();
  };

  const scrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className='Footer'>
      <div className='FooterInnerContainer'>
        <div className='FooterRow'>
          <div className='FooterLinks'>
            <Link
              to={{ pathname: '/termsconditions' }}
              onClick={() => scrollTop()}
            >
              Terms & Conditions
            </Link>
            <Link
              to={{ pathname: '/privacy-policy' }}
              onClick={() => scrollTop()}
            >
              Privacy Policy
            </Link>
            <Link to={{ pathname: '/imprint' }} onClick={() => scrollTop()}>
              Imprint
            </Link>
          </div>
          <div className='FooterCopyright'>
            <span>Copyright &#169; {getYear()} Repify</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
