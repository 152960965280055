import React from 'react';
import './Home.css';

import FrontMainSection from '../../components/organisms/FrontMainSection/FrontMainSection';
import FrontLogWorkoutsSection from '../../components/organisms/FrontLogWorkoutsSection/FrontLogWorkoutsSection';
import FrontProgressSection from '../../components/organisms/FrontProgressSection/FrontProgressSection';
import FrontSocialSection from '../../components/organisms/FrontSocialSection/FrontSocialSection';

const Home: React.FC = () => {
  return (
    <div className='Home'>
      <div className='HomeInnerContainer'>
        <FrontMainSection />
        <FrontLogWorkoutsSection />
        <FrontProgressSection />
        <FrontSocialSection />
      </div>
    </div>
  );
};

export default Home;
