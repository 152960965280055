import React, { useContext } from 'react';
import './App.css';

import Navigation from './components/organisms/navigation/Navigation';
import FooterDownload from './components/organisms/footerDownload/FooterDownload';
import Footer from './components/organisms/footer/Footer';

import { Routes, Route } from 'react-router-dom';

import { ViewportContext, Viewport } from './providers/ViewportProvider';

// Pages
import Home from './pages/home/Home';
import About from './pages/about/About';
import Imprint from './pages/imprint/Imprint';
import PrivacyPolicy from './pages/privacyPolicy/PrivacyPolicy';
import TermsConditions from './pages/termsConditions/TermsConditions';
import ResetPassword from './pages/resetPassword/ResetPassword';

const App: React.FC = () => {
  const { viewport } = useContext(ViewportContext);

  const className = () => {
    let cssClass = 'App';
    if (viewport === Viewport.S) cssClass += ' Small';
    if (viewport !== Viewport.L) cssClass += ' Medium';
    if (viewport === Viewport.L) cssClass += ' Large';

    return cssClass;
  };

  return (
    <div className={className()}>
      <Navigation />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='about' element={<About />} />
        <Route path='imprint' element={<Imprint />} />
        <Route path='privacy-policy' element={<PrivacyPolicy />} />
        <Route path='termsconditions' element={<TermsConditions />} />
        <Route path='resetPassword/:id' element={<ResetPassword />} />
        <Route path='*' element={<Home />} />
      </Routes>
      <FooterDownload />
      <Footer />
    </div>
  );
};

export default App;
